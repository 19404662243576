import React from 'react';
import './contact.scss';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

function Contact() {
  return (
    <>
        <div className='contact'>
            <Navbar />
        </div>

        <Footer />
    </>
  )
}

export default Contact;